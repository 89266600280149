jQuery(document).ready(function() {
    jQuery('a').bind('click', function() {
        if ('#' === jQuery(this).prop('href').slice(-1)) {
            return false;
        }
    });

    jQuery('a').each(function() {
        if ('#' === jQuery(this).prop('href').slice(-1)) {
            jQuery(this).css('cursor', 'default');
        }
    });

    jQuery('#map').hover(function(){
        jQuery(this).css('bottom', '-100px');
    }, function() {
        jQuery(this).css('bottom', '-200px');
    })

    jQuery('.plainText.hidden').click(function() {
        jQuery(this).toggleClass('hidden');
    });
});
