import './legacy/swipeevent.js';
import './legacy/script.js';
import './legacy/mobile.js';
import './legacy/background-slider.js';
import './legacy/collection-slider.js';

class Site {
    public static discover($parent?: JQuery): void {
    }

    public static init() {
        (<any> window).Site = Site;

        $(document).ready(() => {
        });

        $(window).on('load', () => {
            $('body').addClass('body--loaded');

            setTimeout(() => {
                $('body').removeClass('body--loading');
                $('body').trigger('pageloaded');
            }, 1000);
        });
    }
}

Site.init();
