$(document).ready(function(){
    BackgroundSlider.prepare();
});

$(window).resize(function(){
    BackgroundSlider.resize();
});

$(window).load(function(){
    BackgroundSlider.resize();

    BackgroundSlider.run();
});


var BackgroundSlider = {};

BackgroundSlider.run = function()
{
    var element = 0;
    var elemCount = jQuery('#background-slider img').length;

    jQuery('#background-slider img:eq('+element+')').css({opacity: 1});

    if (2 > elemCount) {
        return;
    }

    setInterval(function() {
        jQuery('#background-slider img:eq('+element+')').css({opacity: 0});

        ++element;

        if (element+1 > elemCount) {
            element = 0;
        }

        jQuery('#background-slider img:eq('+element+')').css({opacity: 1});
    }, 6000);
}

BackgroundSlider.prepare = function()
{
    jQuery('#background-slider img').css('opacity', 0);

    setTimeout(function(){
        jQuery('#background-slider img').each(function(){
            jQuery(this).addClass('slide');
        });
    }, 200);
}

BackgroundSlider.resize = function()
{
    jQuery('#background-slider img').each(function() {
        var sizes = BackgroundSlider.getBackgroundSliderImageSize(jQuery(this).width(), jQuery(this).height());

        jQuery(this).css({
            'height': sizes['height']+'px',
            'width': sizes['width']+'px',
            'top': sizes['offsetTop']+'px',
            'left': sizes['offsetLeft']+'px'
        });
    });
}

BackgroundSlider.getBackgroundSliderImageSize = function (imageWidth, imageHeight)
{
    if ('undefined' === typeof imageWidth) {
        imageWidth = 1920;
    }

    if ('undefined' === typeof imageHeight) {
        imageHeight = 1080;
    }

    var windowWidth = window.innerWidth;
    var windowHeight = window.innerHeight;

    var imageRatio = imageWidth/imageHeight;
    var windowRatio = windowWidth/windowHeight;

    if (imageRatio > windowRatio) {
        var newImageWidth = imageWidth * windowHeight/imageHeight;
        var newImageHeight = windowHeight;
        var imageOffsetWidth = 0 - (newImageWidth - windowWidth) / 2;
        var imageOffsetHeight = 0;
    } else {
        var newImageWidth = windowWidth;
        var newImageHeight = imageHeight * windowWidth/imageWidth;
        var imageOffsetWidth = 0;
        var imageOffsetHeight = 0 - (newImageHeight - windowHeight) / 2;
    }

    var sizes = [];

    sizes['height'] = newImageHeight;
    sizes['width'] = newImageWidth;
    sizes ['offsetTop'] = imageOffsetHeight;
    sizes ['offsetLeft'] = imageOffsetWidth;

    return sizes;
}
