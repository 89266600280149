jQuery(document).ready(function() {
    jQuery('#mobile-button').bind('click', function() {
        Mobile.toggleMenu();
    });
});

var Mobile = {};

Mobile.toggleMenu = function() {
    jQuery('#main_menu').toggle(0);
}
